.containt {
    padding: 0.1rem 0 0 0;
}
.dailylist {
    padding: 0 0.1rem;
    .item {
        position: relative;
        z-index: 1;
        color: #333;
        padding: 0.16rem 0.3rem 0.16rem 0.16rem;
        margin-left: 0.52rem;
        text-align: justify;
        word-break: break-all;
        &:before {
            content: "";
            border-left: 1px solid #e0e0e0;
            position: absolute;
            left: 0;
            top: 0;
            height: 100%;
            width: 0.01rem;
            /*transform: scaleX(.5);*/
        }
        &:after {
            content: "";
            border-bottom: 1px solid #e0e0e0;
            position: absolute;
            left: 0.1rem;
            right: 0;
            bottom: 0;
            /*width: 100%;*/
            height: 1px;
            transform: scaleY(0.5);
        }
        .time {
            /*width: .6rem;*/
            font-size: 0.12rem;
            color: #999;
            /*display: block;*/
            position: absolute;
            top: 0.16rem;
            left: -0.6rem;
            white-space: nowrap;
            text-align: right;
        }
        .date,
        .year {
            display: block;
            min-width: 0.3rem;
            text-align: right;
            line-height: 1.4;
        }
        .date {
            font-size: 0.16rem;
        }
        .watcher {
            position: absolute;
            width: 0.2rem;
            height: 0.2rem;
            border-radius: 50%;
            object-fit: cover;
            left: 50%;
            bottom: -0.16rem;
            transform: translateX(-50%);
            box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
        }
        .role {
            position: absolute;
            left: -0.13rem;
            top: 0.15rem;
            width: 0.28rem;
            height: 0.28rem;
            line-height: 0.26rem;
            border-radius: 50%;
            // overflow: hidden;
            border: 0.02rem solid #fff;
            box-sizing: border-box;
            color: #fff;
            background: #b7eaff;
            text-align: center;
            box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
            font-size: 0.16rem;
            text-shadow: 0.01rem 0.01rem 0 rgba(0, 0, 0, 0.2);
            &.female {
                background-color: #ffb7b7;
            }
            svg {
                border-radius: 50%;
                width: 100%;
                height: 100%;
            }
        }
    }
    .something {
        padding-top: 0.05rem;
    }
    .btm-info {
        padding: 0.08rem 0 0 0;
        .addr {
            font-size: 0.12rem;
            color: rgb(185, 185, 185);
            .icon {
                width: 0.16rem;
            }
        }
    }
    .txt {
        font-size: 0.15rem;
        line-height: 1.6;
        white-space: pre-wrap;
    }
    .imglist {
        padding-top: 0.05rem;
    }
    .conner {
        position: absolute;
        right: 0;
        top: 0.1rem;
        z-index: 5;
        em {
            font-size: 0.12rem;
            color: #999;
            padding-left: 0.05rem;
            line-height: 1;
        }
        .icon {
            width: 0.16rem;
            height: 0.16rem;
            fill: #aaa;
        }
    }
    .digged .icon {
        fill: red;
    }
    .digged em {
        color: red;
    }
}
.imglist {
    .img {
        display: inline-block;
        vertical-align: top;
        width: 100%;
        height: 1rem;
        line-height: 1rem;
        text-align: center;
        font-size: 0.3rem;
        color: #666;
        background-repeat: no-repeat;
        background-position: center center;
        background-size: 100% auto;
        background-color: #efefef;
        overflow: hidden;
        position: relative;
        img {
            width: 100%;
            transform: translateY(-35%);
        }
    }
}
.imglistmulti {
    .img {
        /*flex: 1;*/
        margin-right: 0.03rem;
        /*width: 1rem;*/
        /*height: auto;*/
        /*max-height: 1rem;*/
        img {
            transform: translateY(0);
            object-fit: cover;
            height: 100%;
            &:last-child {
                margin-right: 0;
            }
        }
    }
}
.img_50,
.img_33 {
    margin-bottom: -0.03rem;
}
.img_50 .img {
    width: 49%;
    margin-bottom: 0.03rem;
}
.img_50 .img:nth-child(even) {
    margin-right: 0;
}
.img_33 .img {
    width: 32.33%;
    margin-bottom: 0.03rem;
}
.img_33 .img:nth-child(3n + 0) {
    margin-right: 0;
}

.img .icon-play,
.something .icon-play,
.form-list .item .icon-play {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.4);
}
.icon-play svg {
    width: 0.22rem;
    height: 0.22rem;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate3d(-50%, -50%, 0);
    fill: #fff;
}

.mod-video {
    background: rgba(0, 0, 0, 0.8);
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 1001;
    .video-react {
        width: 100%;
        background: #e0e0e0 url(../../static/images/ellipsis.svg) no-repeat
            center center;
        background-size: auto 0.3rem;
        height: auto;
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
    }
    .close {
        position: absolute;
        z-index: 2;
        width: 0.28rem;
        height: 0.28rem;
        border: 1px solid #fff;
        border-radius: 50%;
        overflow: hidden;
        right: 0.1rem;
        top: 0.1rem;
    }
    .close:before,
    .close:after {
        content: "";
        width: 96%;
        left: 2%;
        height: 0.01rem;
        background: #fff;
        position: absolute;
        top: 50%;
        transform: rotate(45deg);
    }
    .close:after {
        transform: rotate(-45deg);
    }
}
.btn-post-index {
    position: fixed;
    width: 0.5rem;
    height: 0.5rem;
    line-height: 0.5rem;
    box-shadow: 0 0 0.15rem rgba(0, 0, 0, 0.2);
    right: 0.2rem;
    bottom: 0.3rem;
    border-radius: 50%;
    overflow: hidden;
    text-align: center;
    background: rgba(255, 255, 255, 0.85);
    z-index: 1;
    & > svg {
        width: 0.35rem;
        height: 100%;
    }
}

.page-detail {
    width: 100%;
    position: fixed;
    padding: 0.35rem 0.3rem 0.2rem 0.3rem;
    z-index: 1;
    left: 0;
    top: 1rem;
    bottom: 0;
    border-radius: 0.3rem 0.3rem 0 0;
    background-color: #fff4e5;
    display: flex;
    flex-direction: column;
    &::after {
        content: "";
        width: 0;
        height: 0;
        position: absolute;
        left: 0.45rem;
        top: -0.28rem;
        border: 0.15rem solid transparent;
        border-left-width: 0.12rem;
        border-right-width: 0.12rem;
        border-bottom-color: #fff4e5;
    }
    .post-info {
        margin-bottom: 0.3rem;
        color: #666;
        font-style: italic;
        font-size: 0.13rem;
        .items {
            display: inline-block;
            margin-right: 0.15rem;
        }
        .cates {
            border-bottom: 0.01rem dotted #e0e0e0;
        }
    }
    .contain {
        position: relative;
        padding-left: 0.6rem;
        flex-grow: 1; // 自适应剩余高度，做滚动隐藏处理溢出内容
        overflow: hidden;
        overflow-y: auto;
        &::before {
            content: "";
            position: absolute;
            width: 0.5rem;
            height: 0.5rem;
            left: 0;
            top: -0.15rem;
            background: url(../../static/images/icon-dot.svg) no-repeat left top;
            background-size: cover;
        }
    }
    .img-wrap {
        margin-bottom: 0.4rem;
    }
    .hd-avatar {
        position: absolute;
        width: 0.6rem;
        height: 0.6rem;
        // overflow: hidden;
        border-radius: 50%;
        background-color: #fff;
        padding: 0.05rem;
        left: 0.25rem;
        top: -0.8rem;
        filter: drop-shadow(0 1px 2px rgba(0, 0, 0, 0.5));
        -webkit-filter: drop-shadow(0 0.02rem 0.04rem rgba(0, 0, 0, 0.45));
        .watcher {
            position: absolute;
            width: 0.2rem;
            height: 0.2rem;
            border-radius: 50%;
            object-fit: cover;
            left: 50%;
            bottom: -0.1rem;
            transform: translateX(-50%);
            box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
        }
        & > svg {
            width: 100%;
            height: 100%;
            border-radius: 50%;
        }
    }
    & + .footer {
        position: absolute;
        bottom: 0;
        z-index: 2;
        width: 100%;
        left: 0;
    }
}
.simulate .page-detail {
    width: 500px;
    left: 50%;
    transform: translateX(-50%);
    background-color: #fff;
    &::after {
        border-bottom-color: #fff;
    }
}
