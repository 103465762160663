@import url(./var.css);

/* iphone 4、4s、5、5s */
@media (min-device-width: 320px) and (max-device-width: 667px) and (-webkit-min-device-pixel-ratio: 2) {
    html {
        font-size: 85.3333px;
    }
}
/* iphone 6 */
@media (min-device-width: 375px) and (max-device-width: 667px) and (-webkit-min-device-pixel-ratio: 2) {
    html {
        font-size: 100px;
    }
}
/* iphone6 plus */
@media (min-device-width: 414px) and (max-device-width: 736px) and (-webkit-min-device-pixel-ratio: 3) {
    html {
        font-size: 110.4px;
    }
}

html {
    color: #222;
    -webkit-text-size-adjust: none !important;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    background-color: #fff;
}
.simulate {
    background-color: rgb(58, 58, 58);
    background-image: url("../../static/images/background2x.jpg");
    background-repeat: repeat;
    background-size: 600px auto;
    min-height: 100%;
    body {
        min-height: 100%;
    }
}
* {
    margin: 0;
    padding: 0;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}
article,
aside,
footer,
header,
nav,
section {
    display: block;
}
audio,
canvas,
video {
    display: inline-block;
}
body,
button,
input,
select,
textarea {
    font: 16px/1.5 "PingFangSC-Regular", "Helvetica Neue", tahoma, arial,
        sans-serif;
    word-wrap: break-word;
    word-break: break-word;
}
h1,
h2,
h3,
h4,
h5,
h6 {
    font-size: 1em;
}
a,
a:visited {
    color: #222;
    text-decoration: none;
    -webkit-touch-callout: none;
}
a:active {
    outline: 0 none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
strong,
em,
i {
    font-size: 1em;
    font-style: normal;
    font-weight: normal;
}
input,
select,
textarea,
button {
    font-size: 1em;
    outline: none;
}
textarea {
    resize: vertical;
    overflow: auto;
    vertical-align: top;
}
input[type="text"]:focus,
textarea:focus {
    outline: 0 none;
}
input[type="text"],
input[type="submit"],
input[type="reset"],
input[type="button"],
textarea,
button {
    -webkit-appearance: none;
}
input:-webkit-autofill {
    box-shadow: 0 0 0px 1000px #fff inset;
}
table {
    border-collapse: collapse;
    border-spacing: 0;
}
img,
iframe {
    border: 0 none;
}
img {
    display: inline-block;
    vertical-align: bottom;
    -webkit-touch-callout: none;
}
iframe {
    display: block;
}
ul,
ol {
    list-style: none;
}
li {
    vertical-align: top;
}
/* 清理浮动 */
.cl {
    clear: both;
    display: block;
    overflow: hidden;
    visibility: hidden;
    width: 0;
    height: 0;
    line-height: 0;
}
.cf:before,
.cf:after {
    content: "";
    display: table;
}
.cf:after {
    clear: both;
}
.none {
    display: none;
}

.mod-toast {
    width: 1.4rem;
    height: 1.4rem;
    position: fixed;
    z-index: 99;
    border-radius: 10px;
    background: rgba(0, 0, 0, 0.45);
    text-align: center;
    padding-top: 0.55rem;
    color: #fff;
    font-size: 0.14rem;
    left: 50%;
    top: 50%;
    transform: translate3d(-50%, -50%, 0);
    .txt {
        display: block;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        padding: 0 0.05rem;
    }
}
.footer {
    height: 0.3rem;
    line-height: 0.3rem;
    /*position: fixed;
	left: 0;
	bottom: 0;
	width: 100%;*/
    text-align: center;
    color: #ccc;
    font-size: 0.12rem;
    /*background: -webkit-linear-gradient(bottom,rgba(255, 255, 255, 1), rgba(255, 255, 255, .001) 90%,rgba(255, 255, 255, 0));
	background: linear-gradient(to top,rgba(255, 255, 255, 1), rgba(255, 255, 255, .001) 90%,rgba(255, 255, 255, 0));*/
    a {
        /*color: #0083db;*/
        color: #ccc;
        border-bottom: 1px dotted #ccc;
    }
}

.wigget-installer {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    z-index: 1001;
    height: 0.48rem;
    align-items: center;
    background: rgba(255, 255, 255, 0.9);
    border-top: 0.01rem solid #ccc;
    padding-left: 0.15rem;
    display: none;
    &.show {
        display: flex;
    }
    .icon-logo {
        display: inline-block;
        width: 0.34rem;
        height: 0.34rem;
        border-radius: 0.1rem;
        overflow: hidden;
        background: url(../../static/images/icon-candy.png) no-repeat center
            center;
        background-size: 0.28rem 0.28rem;
        margin-right: 0.1rem;
    }
    .txt {
        font-size: 0.16rem;
        color: rgb(0, 177, 221);
    }
    .close {
        position: absolute;
        width: 0.28rem;
        height: 0.28rem;
        right: 0.15rem;
        &::before,
        &::after {
            content: "";
            width: 100%;
            height: 0.01rem;
            background: #666;
            top: 50%;
            left: 0;
            position: absolute;
            transform: rotate(45deg);
        }
        &::after {
            transform: rotate(-45deg);
        }
    }
}
