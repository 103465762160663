.mod-notfound {
    .icon {
        display: block;
        width: 1.5rem;
        height: 0.73rem;
        margin: 0 auto;
        background: url("../../static/images/404.png") no-repeat;
        background-size: 1.5rem 0.73rem;
    }
    padding: 1.5rem 0;
}
