.icon {
    width: 1em;
    height: 1em;
    vertical-align: -0.15em;
    fill: #8a8a8a;
    overflow: hidden;
}
.head {
    height: 1.6rem;
    padding-top: 0.3rem;
    background: #000
        url("//static.eyan.life/tang/F987B3D663421740B603A63B1ACD0FD7.jpg?imageMogr2/auto-orient/thumbnail/375/strip/quality/50")
        no-repeat center center;
    background-size: cover;
    position: relative;
    overflow: hidden;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    font-size: 0;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.45);
    &:before {
        position: absolute;
        left: 0;
        top: 0;
        content: "";
        background: #000;
        width: 100%;
        height: 100%;
        filter: blur(80px);
        opacity: 0.1;
    }
    .name {
        font-size: 0.14rem;
        color: #333;
        line-height: 1.6;
        position: relative;
        text-shadow: 0 1px 0 #e5e5e5;
        transition: all 0.3s ease-in-out;
        background-image: linear-gradient(
            transparent 0%,
            transparent 50%,
            #fee630 50%,
            #fee630 100%
        );
        &:before {
            font-size: 0.12rem;
            color: #333;
            content: "“";
        }
        &:after {
            font-size: 0.12rem;
            color: #333;
            content: "”";
        }
    }
    .count {
        display: none;
    }
}
.avatar {
    width: 0.8rem;
    height: 0.8rem;
    padding: 0.02rem;
    border-radius: 50%;
    background: #eaeaea;
    position: relative;
    display: inline-block;
    margin-bottom: 0.1rem;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.65);
    /*transition: all .3s ease-in-out;*/
    line-height: 1;
    img {
        width: 100%;
        height: 100%;
        border-radius: 50%;
    }
    .talks {
        position: absolute;
        font-size: 0.12rem;
        color: #333;
        border-radius: 0.03rem;
        background: rgba(255, 255, 255, 0.8);
        white-space: nowrap;
        line-height: 0.24rem;
        height: 0.24rem;
        padding: 0 0.15rem;
        top: -0.2rem;
        left: 50%;
        transform-origin: 0 0;
        transform: translateX(-50%);
        animation: bounceDrop 0.6s 0.7s linear both;
        &:after {
            content: "";
            border: 8px dashed transparent;
            border-top: 8px solid rgba(255, 255, 255, 0.8);
            position: absolute;
            bottom: -16px;
            left: 50%;
            transform: translateX(-50%);
        }
    }
    .icon-redp {
        width: 0.35rem;
        height: 0.35rem;
        position: absolute;
        right: -0.2rem;
        bottom: 0;
        animation: shakeTime 0.25s 1s 3 ease-in-out both;
    }
}
.fixed .avatar .talks {
    display: none;
}
.fixed .avatar .icon-redp {
    width: 0.2rem;
    height: 0.2rem;
    right: -0.1rem;
}
.nope-auth {
    position: fixed;
    width: 100%;
    height: 100%;
    overflow: hidden;
    &:after {
        position: fixed;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background: rgba(255, 255, 255, 0.7);
        content: "";
        z-index: 1;
    }
}
.box-pwd {
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 2;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0.8rem 0 0.3rem 0;
    background: linear-gradient(
        0deg,
        rgba(255, 255, 255, 1),
        rgba(255, 255, 255, 0.08) 90%,
        rgba(255, 255, 255, 0)
    );
    background: gradient(
        linear,
        0 0,
        0 50%,
        from(rgba(255, 255, 255, 0)),
        to(rgba(255, 255, 255, 1))
    );
    & > .txt {
        font-size: 0.16rem;
        color: #333;
        margin-bottom: 0.1rem;
        font-weight: bold;
        .in {
            color: rgb(207, 0, 0);
        }
    }
    .interactive {
        display: flex;
        & > .txt {
            border: 0.01rem solid #e0e0e0;
            background-color: #fff;
            height: 0.32rem;
            padding-left: 0.15rem;
            border-radius: 0;
            .in {
                color: rgb(207, 0, 0);
            }
        }
        & > .sub {
            background-color: #000;
            color: #fff;
            font-size: 0.12rem;
            text-align: center;
            line-height: 0.32rem;
            width: 0.7rem;
            border: 0 none;
        }
        & > .disabled {
            background: #d2d2d2;
            color: rgb(134, 134, 134);
        }
    }
}
@keyframes shakeTime {
    0% {
        transform: rotate(0deg) scale(1);
    }
    33% {
        transform: rotate(15deg) scale(1.2);
    }
    66% {
        transform: rotate(-15deg) scale(0.95);
    }
    100% {
        transform: rotate(0deg) scale(1);
    }
}
@keyframes bounceDrop {
    0% {
        opacity: 0;
        transform: scale(0.8) translateX(-50%);
    }
    33% {
        transform: scale(1.15) translateX(-50%);
    }
    60% {
        transform: scale(0.95) translateX(-50%);
    }
    100% {
        opacity: 1;
        transform: scale(1) translateX(-50%);
    }
}
#main.fixed {
    padding-top: 1.6rem;
}
.pushment #main.fixed {
    padding-top: 0.6rem;
}
@keyframes dropdown {
    0% {
        transform: translateY(-130%);
    }
    100% {
        transform: translateY(0);
    }
}
.fixed .head {
    position: fixed;
    display: block;
    width: 100%;
    height: 0.5rem;
    left: 0;
    top: 0;
    z-index: 99;
    padding: 0 0 0 0.6rem;
    text-align: left;
    animation: dropdown 0.2s 0.3s linear both;
}
.fixed .avatar {
    width: 0.4rem;
    height: 0.4rem;
    position: absolute;
    left: 0.05rem;
    top: 0.05rem;
    margin-bottom: 0;
}
.fixed .head .name {
    line-height: 0.5rem;
}
.fixed .head .count {
    display: block;
    position: absolute;
    right: 0.1rem;
    color: #ececec;
    font-size: 0.12rem;
    top: 50%;
    transform: translateY(-50%);
    padding: 0.02rem 0.05rem;
    background: rgba(0, 0, 0, 0.45);
    border-radius: 0.03rem;
}
.pushment .fixed .head {
    animation: none;
}

.mod-layout {
    background: rgba(0, 0, 0, 0.35);
    position: fixed;
    z-index: 1001;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    .cont {
        background: #fff;
        border-radius: 0.08rem;
        padding: 0.15rem;
        position: absolute;
        width: 90%;
        min-height: 1.8rem;
        top: 50%;
        left: 50%;
        transform: translate3d(-50%, -50%, 0);
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.45);
        transform-origin: 0 0;
        animation: dropbouce 0.8s linear both;
    }
    .close {
        position: absolute;
        right: 0;
        top: -0.5rem;
        width: 0.3rem;
        height: 0.3rem;
        border-radius: 50%;
        overflow: hidden;
        border: 0.01rem solid #fff;
        /*transform: translateX(-50%);*/
        line-height: 999px;
        &:before,
        &:after {
            content: "";
            width: 94%;
            height: 0.01rem;
            background: #fff;
            position: absolute;
            left: 3%;
            top: 50%;
            transform: rotate(45deg);
        }
        &:after {
            transform: rotate(-45deg);
        }
    }
}
@keyframes dropbouce {
    0% {
        opacity: 0;
        transform: scale(0.95) translate3d(-50%, -50%, 0);
    }
    33% {
        transform: scale(1.15) translate3d(-50%, -50%, 0);
    }
    60% {
        transform: scale(0.95) translate3d(-50%, -50%, 0);
    }
    100% {
        opacity: 1;
        transform: scale(1) translate3d(-50%, -50%, 0);
    }
}
.layout-package .cont img {
    width: 100%;
    border-radius: 0.08rem;
}
