.mod-tabs {
    font-size: 0;
    margin: 0 0.2rem 0.2rem 0.2rem;
    white-space: nowrap;
    .item {
        position: relative;
        display: inline-block;
        font-size: 0.14rem;
        line-height: 0.32rem;
        height: 0.32rem;
        margin-right: 0.1rem;
        background: #eaeaea;
        padding: 0 0.1rem;
        border-radius: 5rem;
        a {
            color: #999;
        }
        &:last-child {
            margin-right: 0;
        }
    }
    .active {
        &:after {
            content: "";
            height: 0.08rem;
            background: #fee630;
            position: absolute;
            left: 0.08rem;
            right: 0.08rem;
            bottom: 0.08rem;
            z-index: 1;
        }
        a {
            position: relative;
            z-index: 2;
            color: rgb(83, 83, 83);
        }
    }
}
.push-cont {
    padding: 0 0.2rem 0.3rem 0.2rem;
    position: relative;
    z-index: 1;
    .btn-submit {
        height: 0.25rem;
        line-height: 0.25rem;
        background: black;
        color: #fff;
        border: 0 none;
        font-size: 0.14rem;
        padding: 0 0.2rem;
        &:active {
            background: #666;
        }
    }
}
.form-list {
    padding-bottom: 0.2rem;
    font-size: 0;
    white-space: nowrap;
    display: grid;
    grid-auto-flow: row;
    grid-gap: 0.2rem;
    grid-template-columns: repeat(3, 1fr);
    .ite.pic1,
    &.pic2 {
        grid-template-rows: repeat(1, 1fr);
    }
    &.pic4,
    &.pic5 {
        grid-template-rows: repeat(2, 1fr);
    }
    &.pic7,
    &.pic8,
    &.pic9 {
        grid-template-rows: repeat(3, 1fr);
    }
    .item {
        flex-grow: 1;
        font-size: 0;
        display: inline-block;
        width: 1rem;
        height: 1rem;
        position: relative;
        border: 0.01rem solid #ccc;
        vertical-align: top;
        .remove {
            position: absolute;
            width: 0.2rem;
            height: 0.2rem;
            border-radius: 50%;
            background: rgba(0, 0, 0, 0.45);
            right: -0.08rem;
            top: -0.08rem;
            &:before,
            &:after {
                content: "";
                position: absolute;
                width: 80%;
                height: 0.01rem;
                background: rgba(255, 255, 255, 0.8);
                left: 10%;
                top: 50%;
                transform: rotate(45deg);
            }
            &:after {
                transform: rotate(-45deg);
            }
        }
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
        input {
            display: inline-block;
            height: 0.25rem;
            border: 1px solid #ccc;
            width: 90%;
            font-size: 0.14rem;
            vertical-align: middle;
        }
    }
    .placehold {
        .txt {
            font-size: 0.14rem;
            text-align: center;
            line-height: 1rem;
            white-space: nowrap;
            color: #999;
            position: relative;
            z-index: 2;
            display: inherit;
        }
    }
    .item-oper {
        border: 0 none;
        overflow: hidden;
        .upbtn {
            position: absolute;
            left: 0;
            top: 0;
            font-size: 1.5rem;
            opacity: 0;
        }
    }
}
.addmore {
    width: 100%;
    height: 100%;
    /*overflow: hidden;*/
    font-size: 1rem;
    text-align: right;
    display: inline-block;
    vertical-align: middle;
    .icon {
        vertical-align: 3rem;
    }
    &.small {
        width: 0.24rem;
        height: 0.24rem;
    }
    &.middle {
        width: 0.48rem;
        height: 0.48rem;
    }
    &.large {
        width: 0.96rem;
        height: 0.96rem;
    }
    &.small,
    &.middle,
    &.large {
        font-size: 0;
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
    img {
        width: 1.1rem;
        height: 1.1rem;
        margin-left: -0.05rem;
        margin-top: -0.05rem;
    }
}

.editor {
    // margin-bottom: 0.2rem;
    border: 1px solid #ccc;
    min-height: 1.4rem;
    padding: 0.13rem;
    textarea {
        width: 100%;
        height: 1.4rem;
        font-size: 0.14rem;
        border: 1px solid #ccc;
        padding: 0.1rem;
        border-radius: 0;
    }
    .wrap-toolbar {
        margin: -0.14rem -0.14rem 0.15rem -0.14rem;
    }
    .public-DraftStyleDefault-block,
    &:global(.public-DraftEditor-content) {
        font-size: 0.14rem;
    }
}
.tab-sex {
    display: flex;
    padding-left: 0.25rem;
    overflow: hidden;
    margin-bottom: 0.2rem;
    // justify-content: flex-end;
    margin-top: -0.01rem;
    .item {
        position: relative;
        padding: 0 0.04rem;
        height: 0.3rem;
        border-bottom: 0.01rem solid #ccc;
        color: #999;
        background-color: #eaeaea;
        margin-right: 0.2rem;
        font-size: 0.14rem;
        &.active {
            background-color: #fff;
            z-index: 2;
            .txt {
                color: #535353;
                background-image: linear-gradient(
                    transparent 0%,
                    transparent 50%,
                    #fee630 50%,
                    #fee630 100%
                );
            }
            &::before,
            &::after {
                background-color: #fff;
            }
        }
        .txt {
            position: relative;
            z-index: 2;
            padding: 0 0.05rem;
            line-height: 0.28rem;
        }
        &::before,
        &::after {
            position: absolute;
            z-index: 1;
            background-color: #eaeaea;
        }
        &::before {
            content: "";
            height: 0.35rem;
            width: 0.3rem;
            border-left: 0.01rem solid #ccc;
            left: -0.13rem;
            top: -0.11rem;
            transform: rotate(-35deg);
        }
        &::after {
            content: "";
            height: 0.35rem;
            width: 0.3rem;
            border-right: 0.01rem solid #ccc;
            right: -0.13rem;
            top: -0.11rem;
            transform: rotate(35deg);
        }
    }
}
.mod-operation {
    // border-top: .01rem dotted #e7e7e7;
    padding-top: 0.1rem;
    display: flex;
}
.richtext {
    padding: 0.2rem 0.2rem 0 0.2rem;
    margin-bottom: 0.1rem;
    dt {
        color: #333;
        margin-bottom: 0.05rem;
        font-size: 0.16rem;
    }
    dd {
        font-size: 0.14rem;
        color: #666;
        line-height: 1.7;
        margin-bottom: 0.1rem;
        a {
            color: #00b2d1;
            border-bottom: 0.01rem dotted #00b2d1;
            text-decoration: none;
        }
    }
    .tag-list {
        font-size: 0;
        margin-bottom: -0.1rem;
        a {
            font-size: 0.14rem;
            display: inline-block;
            border-right: 1px solid #c6c6c6;
            border-bottom: 1px solid #c6c6c6;
            background: #efefef;
            color: #666;
            padding: 0 0.15rem;
            line-height: 2;
            margin: 0 0.1rem 0.1rem 0;
        }
    }
}

.mod-list {
    .item {
        padding: 0.12rem 0;
        border-bottom: 0.01rem dashed #e0e0e0;
        position: relative;
        .link {
            display: inline-block;
            cursor: pointer;
            display: inline-block;
            vertical-align: middle;
            color: #00b2d1;
            width: 60%;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            font-size: 0.16rem;
            img {
                display: inline-block;
                width: 0.25rem;
                height: 0.25rem;
                object-fit: cover;
                background-color: #ccc;
            }
        }
        .time {
            display: inline-block;
            vertical-align: middle;
            color: #aaa;
            font-size: 0.14rem;
        }
        .operate {
            position: absolute;
            top: 0.16rem;
            right: 0;
        }
    }
}
.operate .icon {
    fill: red;
    margin-left: 0.1rem;
}

.mod-page {
    padding: 0.16rem 0;
    text-align: center;
    font-size: 0;
    margin-bottom: 0.3rem;
    overflow: hidden;
    white-space: nowrap;
    overflow-x: auto;
    li {
        display: inline-block;
        font-size: 0.14rem;
        margin: 0 0.1rem;
        border: 0.01rem solid #e0e0e0;
        width: 0.3rem;
        height: 0.3rem;
        line-height: 0.3rem;
        text-align: center;
        a {
            color: #999;
        }
    }
    .active {
        border-color: #333;
        color: #333;
        a {
            color: #333;
        }
    }
}

.setlist {
    dt {
        font-size: 0.14rem;
        margin-bottom: 0.1rem;
        color: #666;
    }
    dd {
        margin-bottom: 0.2rem;
    }
    input {
        height: 0.36rem;
        box-sizing: border-box;
        padding-left: 0.2rem;
        border: 0.01rem solid #ccc;
        width: 100%;
    }
}
.spe input {
    width: 1rem;
    margin-right: 0.2rem;
    text-align: center;
    padding-left: 0;
}
.addr-recommend {
    padding: 0 0 0.1rem 0;
    margin-top: -0.05rem;
    white-space: nowrap;
    overflow: hidden;
    overflow-x: auto;
    .item {
        display: inline-block;
        font-size: 0.12rem;
        color: #999;
        border: 0.01rem solid #d1d1d1;
        border-radius: 50rem;
        margin-right: 0.1rem;
        padding: 0 0.1rem;
        position: relative;
        em {
            position: relative;
            z-index: 2;
        }
        &.active {
            color: #535355;
            &:after {
                content: "";
                height: 0.05rem;
                background: #fee630;
                position: absolute;
                left: 0.08rem;
                right: 0.08rem;
                bottom: 0.02rem;
                z-index: 1;
            }
        }
    }
}
.wave-wrap {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    z-index: 1;
}
.wave-animation-water {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    transform: translate(0, calc(100% - 0%));
    background: rgba(0, 0, 0, 0.35);
    transition: all 2s ease-in-out;
}
.mod-waw {
    width: 200%;
    position: absolute;
    bottom: 100%;
}
.waw-back {
    right: 0;
    fill: rgba(0, 0, 0, 0.35);
    animation: wave-back 1.4s infinite linear;
}
.waw-front {
    left: 0;
    fill: rgba(0, 0, 0, 0.15);
    margin-bottom: -1px;
    animation: wave-front 0.7s infinite linear;
}
@keyframes wave-front {
    100% {
        transform: translate(-50%, 0);
    }
}
@keyframes wave-back {
    100% {
        transform: translate(50%, 0);
    }
}

.wrap-category {
    padding: 0.15rem;
    position: relative;
    border: 0.01rem solid #ccc;
    // margin-bottom: 0.2rem;
    .title {
        background: #fff;
        padding: 0 0.1rem;
        position: absolute;
        left: 0.15rem;
        top: -0.1rem;
        font-size: 0.14rem;
        color: #666;
        line-height: 1;
    }
    .list {
        overflow: hidden;
        overflow-x: auto;
        white-space: nowrap;
        font-size: 0;
        &::-webkit-scrollbar {
            display: none;
        }
        .item {
            display: inline-block;
            font-size: 0.14rem;
            line-height: 0.24rem;
            height: 0.24rem;
            border-radius: 50rem;
            background-color: #eaeaea;
            color: #999;
            padding: 0 0.1rem;
            margin-right: 0.1rem;
            &.item-cates {
                background: #fff;
                border: 0.01rem solid #eaeaea;
                position: relative;
                .input-cate {
                    height: 100%;
                    width: 0.5rem;
                    border: 0 none;
                    background: none;
                    line-height: normal;
                }
                .icon-change {
                    position: absolute;
                    width: 0.18rem;
                    height: 0.18rem;
                    background: url(../../static/images/icon-success.svg)
                        no-repeat center center;
                    background-size: 0.12rem auto;
                    border-radius: 50%;
                    background-color: #eaeaea;
                    top: 50%;
                    right: 0;
                    transform: translate3d(0.1rem, -50%, 0);
                }
            }
            .icon {
                display: inline-block;
                object-fit: cover;
                width: 0.16rem;
            }
            &.active {
                color: #666;
                .txt {
                    background-image: linear-gradient(
                        transparent 0%,
                        transparent 50%,
                        #fee630 50%,
                        #fee630 100%
                    );
                }
            }
        }
    }
}

.headlineButtonWrapper {
    display: inline-block;
}

.headlineButton {
    background: #fbfbfb;
    color: #888;
    font-size: 18px;
    border: 0;
    vertical-align: bottom;
    height: 34px;
    width: 30px;
}

.headlineButton:hover,
.headlineButton:focus {
    background: #f3f3f3;
}

.btn-upload {
    font-size: 0;
    position: relative;
    display: inline-block;
    &.small {
        width: 0.24rem;
        height: 0.24rem;
    }
    &.middle {
        width: 0.48rem;
        height: 0.48rem;
    }
    &.large {
        width: 0.96rem;
        height: 0.96rem;
    }
    &.small,
    &.middle,
    &.large {
        svg {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
}
