#root {
    .fixed {
        padding-top: 1.6rem;
        &.pushment {
            padding-top: 0.7rem;
            .head {
                animation: none;
            }
            .footer {
                position: fixed;
                width: 100%;
                left: 0;
                bottom: 0;
                background: linear-gradient(
                    to top,
                    rgba(255, 255, 255, 1),
                    rgba(255, 255, 255, 0)
                );
                &.iphoneX {
                    bottom: 0.34rem;
                    & + .push-cont {
                        padding-bottom: 0.8rem;
                    }
                }
            }
        }
    }
}
.page-index {
    background-color: #f4eaea;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='100' height='199' viewBox='0 0 100 199'%3E%3Cg fill='%239C92AC' fill-opacity='0.27'%3E%3Cpath d='M0 199V0h1v1.99L100 199h-1.12L1 4.22V199H0zM100 2h-.12l-1-2H100v2z'%3E%3C/path%3E%3C/g%3E%3C/svg%3E");
    background-repeat: repeat;
}
.loading {
    height: 1.5rem;
    background: url(../../static/images/ellipsis.svg) no-repeat center center;
    background-size: auto 0.3rem;
}
.minload {
    height: 0.5rem;
}
.noreault {
    height: 0.5rem;
    line-height: 0.5rem;
    background: none;
    font-size: 0.12rem;
    color: #999;
    text-align: center;
}
.icon-loading {
    display: inline-block;
    height: 0.2rem;
    width: 1rem;
    background: url(../../static/images/ellipsis.svg) no-repeat center center;
    background-size: auto 0.2rem;
}
.noresult {
    height: 1.5rem;
    padding-top: 0.65rem;
    text-align: center;
    color: #999;
    line-height: 1.4;
    .txt {
        display: block;
    }
    .refresh {
        font-size: 0.12rem;
        color: #999;
        border-bottom: 1px dotted #999;
    }
}
