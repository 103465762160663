/* 
* CSS 变量全局
* @creater Dawson
* @date 2022/08/11
 */

:root:root {
    --main-color: #fee630;
    --rv-primary-color: var(--main-color);
    --rv-button-primary-background-color: var(--main-color);
    --rv-radio-checked-icon-color: var(--rv-primary-color);
}
.rv-icon {
    --rv-white: #666;
}
.form-public .rv-cell {
    --rv-cell-horizontal-padding: 0;
    --rv-field-label-width: 3.5em;
}
