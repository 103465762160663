.page-login {
    padding-top: 1rem;
    .avatar {
        display: block;
        box-shadow: 0 0 1px #999;
        margin: 0 auto 0.3rem auto;
    }
}
.mod-login {
    width: 80%;
    margin: 0 auto 3rem auto;
    .item {
        margin-bottom: 0.2rem;
    }
    input {
        width: 100%;
        height: 0.4rem;
        border: 0 none;
        border-bottom: 0.01rem solid #aaa;
        /*line-height: .32rem;*/
        color: #333;
        /*padding-left: .1rem;*/
        box-sizing: border-box;
        border-radius: 0;
    }
    button {
        width: 0.8rem;
        height: 0.36rem;
        line-height: 0.36rem;
        border: 0 none;
        background: #000;
        color: #fff;
    }
    .loging {
        background: #d2d2d2;
        color: rgb(134, 134, 134);
    }
}
